import React, { FC } from 'react';
import { graphql } from 'gatsby';
import { MDXRenderer } from 'gatsby-plugin-mdx';
import { GetMdxContentPagesQuery } from '@generated/graphql';
import { DefaultPageProps } from '@layouts/types';
import { BaseFooter } from '@components/base-footer/base-footer';
import { BaseNavigation } from '@components/base-navigation/base-navigation';
import { HTMLHead } from '@components/html-head/html-head';

import { StaticAssetsProvider } from '@providers/static-assets';

export const query = graphql`
    query GetMdxContentPages($mdxId: String!) {
        mdx(id: { eq: $mdxId }) {
            body
        }
        allFile {
            nodes {
                publicURL
                relativePath
                childImageSharp {
                    id
                    gatsbyImageData(width: 800, placeholder: BLURRED, quality: 90)
                }
            }
        }
    }
`;

const BaseLayout: FC<DefaultPageProps<GetMdxContentPagesQuery>> = (props) => {
    const { pageContext, data, children } = props;

    return (
        <>
            <StaticAssetsProvider assets={data.allFile.nodes}>
                <HTMLHead title={pageContext?.frontmatter?.title as string | undefined} />
                <BaseNavigation />
                <main>
                    {data?.mdx && <MDXRenderer>{data.mdx.body}</MDXRenderer>}
                    {children}
                </main>
                <BaseFooter />
            </StaticAssetsProvider>
        </>
    );
};

export default BaseLayout;
